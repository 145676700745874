import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { notification } from 'antd';
import {
  AppStore,
  CarStore,
  CurrentUserStore,
  ShopStore,
  WorkOrderStore,
} from 'stores';

import {
  Typography,
  Row,
  Col,
  Divider,
  Dropdown,
  Button,
  Menu,
  Input,
  Icon,
  Modal,
} from 'antd';
import { Logger } from 'stores/Classes';
import VehicleDefectsTable from 'components/DVIR/VehicleDefectsTable';
import CreateWorkOrderModal from 'containers/WorkOrder/modals/CreateWorkOrderModal';
import CreateTMTWorkOrderModal from 'containers/WorkOrder/modals/CreateTMTWorkOrderModal';
import { get } from 'lodash';

const DarkHeaderModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-close {
      .ant-modal-close-x {
        color: #ffffff;
      }
    }

    .ant-modal-header {
      background-color: #05103a;
      border-bottom: 0;

      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-body {
      padding: 12px 18px;
    }
  }
`;

const TextArea = styled(Input.TextArea)`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
  min-height: 64px;
  transition: all 0.3s;
  line-height: 1.5715;
  position: relative;
  word-wrap: break-word;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .tag {
    margin-left: 1rem;
    font-size: 1.3rem;
    padding: 5px 15px;
  }
`;

const VehicleDefectsRow = styled(Row)`
  .card {
    border-radius: 20px;
  }

  .image {
    border-radius: 20px 20px 0 0;
  }
`;

const GeneralDataGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;

  .grid-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const SecondRowGeneralDataGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin-top: 1rem;

  .grid-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const SignatureRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .driver-signature {
    border-top: 1px solid black;
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
  }
`;

const PitstopDropdown = styled(Dropdown)``;

const ButtonWrapper = styled.div`
  button.ant-btn {
    color: #333;
    font-weight: bold;
    font-size: 1.2rem;
    padding-bottom: 30px;
    padding: 0.2rem 1.3rem;
    border-radius: 0.3rem;
    display: flex;
    width: 160px;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    border: 1px solid #d9d9d9;
    background-color: white;

    &.green {
      border-color: green;
      color: green;
    }

    &.red {
      border-color: red;
      color: red;
    }

    &.ocean {
      border-color: #003a8c;
      color: #003a8c;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: #f5f5f5;
    }
  }
`;

const ConfirmationRow = styled(Row)``;

const { Title, Text } = Typography;

class DVIRDetailsPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        carId: PropTypes.string.isRequired,
      }),
    }),
  };

  state = {
    isResolvingDVIR: false,
    dvirData: {},
    loading: false,
    samsaraUserSelectedEmail: null,
    mechanicNotes: '',
    resolveDefectModalVisible: false,
    samsaraUsers: [],
    resolveSelectedDefectsModalVisible: false,
    isResolvingDefects: false,
    isCreatingWorkOrder: false,
    isCreatingTMTWorkOrder: false,
    mechanicNotesResolvingMultipleDefects: '',
    defectsToBeResolved: [],
    defectsToCreateWorkOrder: [],
    fetchError: null, // To Check If It's Samsara Integration
  };

  componentDidMount() {
    this.init();
  }

  vehicleDefectsTableRef = null;
  trailerDefectsTableRef = null;

  init = async () => {
    try {
      this.setState({ loading: true });
      const carId = this.props?.match?.params?.carId;
      const queryParams = this.props.history?.location?.search;
      let isTrailer = false;
      if (queryParams) {
        const urlParams = new URLSearchParams(queryParams);
        isTrailer = urlParams.get('trailer');
      }
      const dvirData = await CarStore.fetchDVIRsForCars(carId, isTrailer);
      if (dvirData.length) {
        const { record } = dvirData[0];

        // Add to defect, information about Work Orders
        if (record.payload?.vehicleDefects?.length > 0) {
          const workOrderDvirData = await WorkOrderStore.searchWorkOrdersDvir({
            dvirId: record.dvirId,
          });
          if (workOrderDvirData.length) {
            record.payload.vehicleDefects.map((vehicleDefect) => {
              const filteredData = workOrderDvirData.find(
                (woDvir) => woDvir.defect_id.toString() === vehicleDefect.id
              );
              vehicleDefect.workOrder = filteredData;
              return vehicleDefect;
            });
          }
        }

        this.setState({
          dvirData: record,
        });
      }
    } catch (error) {
      Logger.log('Error fetching DVIR data');
      Logger.error(error);
    } finally {
      this.setState({ loading: false });
    }

    try {
      const samsaraUsers = await CarStore.fetchSamsaraUsers(
        ShopStore.currentShop.id
      );
      // Select the current user email if it's in the list
      const currentUserEmail = CurrentUserStore.user.email;
      const currentUser = samsaraUsers.find(
        (user) =>
          String(user.email).toLowerCase() ===
          String(currentUserEmail).toLowerCase()
      );
      if (currentUser) {
        // loop through the list of defects and set the current user as the default
        const dvirData = this.state.dvirData;
        if (
          dvirData?.payload?.vehicleDefects &&
          dvirData?.payload?.vehicleDefects.length
        ) {
          dvirData.payload.vehicleDefects.forEach((defect) => {
            if (!defect.resolvedBySamsaraUserId) {
              defect.shouldDisableSelect = true;
              defect.resolvedBySamsaraUserId = currentUser.id;
            }
          });
        }
        if (
          dvirData?.payload?.trailerDefects &&
          dvirData?.payload?.trailerDefects.length
        ) {
          dvirData.payload.trailerDefects.forEach((defect) => {
            if (!defect.resolvedBySamsaraUserId) {
              defect.shouldDisableSelect = true;
              defect.resolvedBySamsaraUserId = currentUser.id;
            }
          });
        }
      }
      this.setState({ samsaraUsers: samsaraUsers || [] });
    } catch (error) {
      // extract the error message from the error object
      Logger.error(error);
      this.setState({ fetchError: error });
      // AppStore.addError('Error fetching Samsara users');
    }
  };

  formatSafetyStatus = (status) => {
    switch (status) {
      case 'safe':
        return 'SAFE';
      case 'unsafe':
        return 'UNSAFE';
      case 'resolved':
        return 'RESOLVED';
      default:
        return 'N/A';
    }
  };

  getStatusColor = (status) => {
    switch (status) {
      case 'safe':
        return 'green';
      case 'unsafe':
        return 'red';
      case 'resolved':
        return 'geekblue';
      default:
        return 'grey';
    }
  };

  formatType = (type) => {
    switch (type) {
      case 'preTrip':
        return 'Pre-Trip';
      case 'postTrip':
        return 'Post-Trip';
      default:
        return 'N/A';
    }
  };

  handleResolveDVIR = async () => {
    try {
      this.setState({ isResolvingDVIR: true });
      if (!CurrentUserStore.user.email) {
        throw new Error('An user is required to resolve the DVIR');
      }
      await CarStore.resolveDVIR({
        recordId: `${this.state.dvirData.id}`, // requires type string
        samsaraUserEmail: CurrentUserStore.user.email,
        mechanicNotes: this.state.mechanicNotes || 'Resolved by Pitstop',
        shopId: ShopStore.currentShop.id,
      });
      AppStore.addNotification('DVIR resolved successfully');
      // check if the resolve all defects checkbox is checked
      if (document.getElementById('resolveAllDefectsCheckbox').checked) {
        // resolve all defects
        const currentUserEmail = CurrentUserStore.user.email;
        const samsaraUserResolving = this.state.samsaraUsers.find(
          (user) =>
            String(user.email).toLowerCase() ===
            String(currentUserEmail).toLowerCase()
        );
        const defectsResolved = await CarStore.resolveDVIRDefects({
          shopId: ShopStore.currentShop.id,
          dvirId: this.state.dvirData.id,
          defects: get(this, 'state.dvirData.payload.vehicleDefects', []),
          mechanicNotes: this.state.mechanicNotes || '',
          resolvedBySamsaraUserId: samsaraUserResolving?.id || currentUserEmail,
        });
        AppStore.addNotification(
          `${defectsResolved.length} defects resolved successfully`
        );
      }
      this.setState({
        samsaraUserEmail: '',
        mechanicNotes: '',
        resolveDefectModalVisible: false,
      });
      this.init();
    } catch (error) {
      AppStore.addError('Error resolving DVIR');
    } finally {
      this.setState({ isResolvingDVIR: false });
    }
  };

  handleResolveDVIRCancel = () => {
    this.setState(
      {
        mechanicNotes: '',
        resolveDefectModalVisible: false,
      },
      () => {
        // reset the resolve all defects checkbox
        document.getElementById('resolveAllDefectsCheckbox').checked = false;
      }
    );
  };

  handleDVIRResolveClick = async () => {
    this.setState({ resolveDefectModalVisible: true }, () => {
      // focus on the input using react ref
    });
  };

  handleDefectMechanicNotesChange = (defect, value, type = 'vehicles') => {
    const toUpdate = {};

    if (type === 'trailers') {
      toUpdate.trailerDefects = this.state.dvirData?.payload?.trailerDefects.map(
        (d) => {
          if (d.id === defect.id) {
            return {
              ...d,
              mechanicNotes: value,
            };
          }
          return d;
        }
      );
    } else {
      toUpdate.vehicleDefects = this.state.dvirData?.payload?.vehicleDefects.map(
        (d) => {
          if (d.id === defect.id) {
            return {
              ...d,
              mechanicNotes: value,
            };
          }
          return d;
        }
      );
    }

    this.setState({
      dvirData: {
        ...this.state.dvirData,
        payload: {
          ...this.state.dvirData?.payload,
          ...toUpdate,
        },
      },
    });
  };

  handleDefectResolvedByChange = (defect, userId) => {
    this.setState({
      dvirData: {
        ...this.state.dvirData,
        payload: {
          ...this.state.dvirData?.payload,
          vehicleDefects: this.state.dvirData?.payload?.vehicleDefects.map(
            (d) => {
              if (d.id === defect.id) {
                return {
                  ...d,
                  resolvedBySamsaraUserId: userId,
                };
              }
              return d;
            }
          ),
        },
      },
    });
  };

  handleToggleMechanicNoteOnDefectClick = (defect, isVisible) => {
    this.setState({
      dvirData: {
        ...this.state.dvirData,
        payload: {
          ...this.state.dvirData?.payload,
          vehicleDefects: this.state.dvirData?.payload?.vehicleDefects.map(
            (d) => {
              if (d.id === defect.id) {
                return {
                  ...d,
                  mechanicNotesVisible: isVisible,
                };
              }
              return d;
            }
          ),
        },
      },
    });
  };

  handleMechanicNotesChangeSave = async (
    defect,
    mechanicNotes,
    type = 'vehicles'
  ) => {
    try {
      AppStore.addLoading('Saving mechanic notes...');
      await CarStore.saveMechanicNotes({
        dvirId: this.state.dvirData.id,
        defectId: defect.id,
        mechanicNotes: mechanicNotes,
        shopId: ShopStore.currentShop.id,
      });
      AppStore.addNotification('Mechanic notes saved successfully');
      if (type === 'trailers') {
        this.trailerDefectsTableRef.setEditingMechanicNotes(
          this.trailerDefectsTableRef.editingMechanicNotes.filter(
            (id) => id !== defect.id
          )
        );
      } else {
        this.vehicleDefectsTableRef.setEditingMechanicNotes(
          this.vehicleDefectsTableRef.editingMechanicNotes.filter(
            (id) => id !== defect.id
          )
        );
      }
    } catch (error) {
      let errMessage = 'Error saving notes';
      if (error?.message) {
        errMessage = error.message;
      }
      if (errMessage.includes('Author Id is not valid')) {
        const email = CurrentUserStore.user.email;
        errMessage = `To resolve DVIR and defects, please review your Samsara account to make sure your Pitstop e-mail (${email}) matches with your Samsara email. If you want to change your Pitstop email, please contact us.`;
      }
      if (errMessage.includes('failed to call samsara API:')) {
        errMessage = errMessage.replace('failed to call samsara API:', '');
      }
      AppStore.addError(errMessage, {
        duration: 0,
      });
    } finally {
      AppStore.removeLoading();
    }
  };

  handleDefectResolveConfirm = async (defect, type = 'vehicles') => {
    try {
      AppStore.addLoading('Resolving defect...');
      await CarStore.resolveDVIRDefect({
        dvirId: this.state.dvirData.id,
        defectId: defect.id,
        mechanicNotes: defect.mechanicNotes || '',
        isResolved: true,
        resolvedBySamsaraUserId:
          defect.resolvedBySamsaraUserId || CurrentUserStore.user.email,
        shopId: ShopStore.currentShop.id,
      });
      AppStore.addNotification('Defect resolved successfully');
      this.init();
    } catch (error) {
      let errMessage = 'Error resolving defect';
      if (error?.message) {
        errMessage = error.message;
      }
      if (errMessage.includes('Author Id is not valid')) {
        const email = CurrentUserStore.user.email;
        errMessage = `To resolve DVIR and defects, please review your Samsara account to make sure your Pitstop e-mail (${email}) matches with your Samsara email. If you want to change your Pitstop email, please contact us.`;
      }
      if (errMessage.includes('failed to call samsara API:')) {
        // remove 'failed to call samsara API' from the error message
        errMessage = errMessage.replace('failed to call samsara API:', '');
      }
      AppStore.addError(errMessage, {
        duration: 0,
      });
    } finally {
      AppStore.removeLoading();
    }
  };

  handleMarkAllAsResolvedClick = async (defectsToBeResolved = []) => {
    if (defectsToBeResolved.length === 0) {
      AppStore.addError('No defects to resolve');
      return;
    }
    // show modal to confirm
    this.setState({
      resolveSelectedDefectsModalVisible: true,
      defectsToBeResolved,
    });
  };

  handleResolveSelectedDefectsCancel = () => {
    this.setState({ resolveSelectedDefectsModalVisible: false });
  };

  handleResolveSelectedDefects = async () => {
    try {
      this.setState({ isResolvingDefects: true });
      if (!CurrentUserStore.user.email) {
        throw new Error('An user is required to resolve the DVIR');
      }
      const currentUserEmail = CurrentUserStore.user.email;
      const samsaraUserResolving = this.state.samsaraUsers.find(
        (user) =>
          String(user.email).toLowerCase() ===
          String(currentUserEmail).toLowerCase()
      );
      const defectsResolved = await CarStore.resolveDVIRDefects({
        shopId: ShopStore.currentShop.id,
        dvirId: this.state.dvirData.id,
        defects: this.state.defectsToBeResolved,
        mechanicNotes: this.state.mechanicNotesResolvingMultipleDefects || '',
        resolvedBySamsaraUserId: samsaraUserResolving?.id || currentUserEmail,
      });
      AppStore.addNotification(
        `${defectsResolved.length} defects resolved successfully`
      );
      this.setState({
        mechanicNotesResolvingMultipleDefects: '',
        resolveSelectedDefectsModalVisible: false,
      });
      this.init();
    } catch (error) {
      console.error(error);
      AppStore.addError(
        'Error resolving the selected defects. Please try again later'
      );
    } finally {
      this.setState({ isResolvingDefects: false });
    }
  };

  handleDefectResolveClick = async (key, defect, type = 'vehicles') => {
    const toUpdate = {};

    if (type === 'trailers') {
      toUpdate.trailerDefects = this.state.dvirData?.payload?.trailerDefects.map(
        (d) => {
          if (d.id === defect.id) {
            return {
              ...d,
              isResolved: key === 'resolved',
            };
          }
          return d;
        }
      );
    } else {
      toUpdate.vehicleDefects = this.state.dvirData?.payload?.vehicleDefects.map(
        (d) => {
          if (d.id === defect.id) {
            return {
              ...d,
              isResolved: key === 'resolved',
            };
          }
          return d;
        }
      );
    }

    this.setState({
      dvirData: {
        ...this.state.dvirData,
        payload: {
          ...this.state.dvirData?.payload,
          ...toUpdate,
        },
      },
    });
  };

  navegateToNewWorkOrder = (id) => {
    this.props.history.push(`/work-order/${id}/edit`);
  };

  handleCreateWorkOrderClick = async (defectsToCreateWorkOrder = []) => {
    if (defectsToCreateWorkOrder.length === 0) {
      AppStore.addError('No defects to create work order');
      return;
    }

    AppStore.openModals.set('CREATE_WORK_ORDER_MODAL_ID', true);

    // show modal to confirm
    this.setState({
      defectsToCreateWorkOrder,
    });
  };

  handleCreateWorkOrder = async (workOrder) => {
    try {
      this.setState({ isCreatingWorkOrder: true });
      if (!CurrentUserStore.user.email) {
        throw new Error('An user is required to create a work order');
      }

      const newWorkOrder = await this.createWorkOrderFromDefects(workOrder);

      if (!newWorkOrder) {
        return;
      }

      // If successfully created a work order, create the lines
      await this.createWorkOrderLines(newWorkOrder.id);

      notification.open({
        message: 'Work Order created successfully.',
        duration: 0,
        description: (
          <div>
            <button
              type="link"
              onClick={() => this.navegateToNewWorkOrder(newWorkOrder.id)}
              style={{
                textDecoration: 'underline',
                color: '#1890ff',
                border: 'none',
                padding: 0,
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
            >
              View Full Work Order: #{workOrder.woInvoiceNumber}
            </button>
          </div>
        ),
      });

      this.setState({
        defectsToCreateWorkOrder: [],
      });

      // Refresh the states
      this.init();

      this.setState({ isCreatingWorkOrder: false });
      AppStore.openModals.set('CREATE_WORK_ORDER_MODAL_ID', false);
    } catch (error) {
      console.error(error);
      AppStore.addError(
        'Error creating work order for the selected defects. Please try again later'
      );
    } finally {
      this.setState({ isCreatingWorkOrder: false });
      AppStore.openModals.set('CREATE_WORK_ORDER_MODAL_ID', false);
    }
  };

  createWorkOrderFromDefects = async (workOrder) => {
    try {
      let workOrderDvir = [];
      this.state.defectsToCreateWorkOrder.forEach((defect) => {
        workOrderDvir.push({
          dvir_id: this.state.dvirData.dvirId,
          defect_id: Number.isNaN(defect.id) ? defect.id : Number(defect.id),
        });
      });

      const response = await WorkOrderStore.createWorkOrderOnCustomEndpoint({
        workOrder: {
          id_car: +this.props.match.params.carId,
          id_shop: +ShopStore.currentShop.id,
          status: workOrder.woStatus,
          type: workOrder.woRepairType,
          priority: workOrder.woRepairPriority,
          invoice_number: workOrder.woInvoiceNumber,
          created_by: workOrder.woCreatedBy,
          assigned_to: workOrder.woAssignedTo,
          started_at: moment(new Date()).toDate(),
          migrate_source: 'PitStop',
        },
        workOrderDvir,
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  createPriority(value) {
    switch (value) {
      case 'major':
        return 5;
      case 'minor':
        return 1;
      case 'critical':
        return 10;
      default:
        return 5;
    }
  }

  createTMTWorkOrderFromDefects = async (workOrder) => {
    try {
      // GET USERS
      // To be implemented
      
      // Use these values to match PitStop vehicle with TMT vehicle
      const unitTMT = await WorkOrderStore.getCarShopTMTInfo({
        pitstopCarId: +this.props.match.params.carId,
      });

      let workOrderSectionsTMTpayload = [];
      let workOrderDvir = [];

      let highestPriority = Math.max(
        workOrder.map((wo) => {
          return wo.repairPriority;
        })
      );

      const userTimezone =
      CurrentUserStore.user?.settings?.timezone || 'America/Toronto';

      workOrder.forEach((section) => {
        workOrderSectionsTMTpayload.push({
          complaint: section.complaint,
          componentCode: section.componentCode,
          modifiedBy: 'SSHOSTED', // CHange by the real user
          openedDate: moment.tz(moment(), userTimezone).format('YYYY-MM-DDTHH:mm:ss'),
          priority: this.createPriority(section.repairPriority),
          repairReason: section.repairReason,
          sectionComment: section.defectType + ' - ' + section.comment,
          sectionNumber: 0,
          sectionStatus: 'OPEN',
          lines: [],
        });

        // Use to Link Work Orders and DVIR (Defects needs to be attached to TMT Sections/ PitStop Order Lines)
        workOrderDvir.push({
          dvir_id: this.state.dvirData.dvirId,
          defect_id: section.defectId,
          defect_type: section.defectType,
        });
      });

      let workOrderTMTpayload = {
        amountUnitOfMeasure: 'US$',
        modifiedBy: 'SSHOSTED', // CHange by the real user
        openedDate: moment.tz(moment(), userTimezone).format('YYYY-MM-DDTHH:mm:ss'),
        priority: this.createPriority(highestPriority),
        unitId: unitTMT[0].unit_id,
        repairClass: 'BEFOREFAIL', // Always BEFOREFAIL = DVIR on Header
        repairShop: unitTMT[0].repair_shop,
        repairSite: 'FACILITY',
        caller: 'null',
        companyId: 'TMT',
        sections: workOrderSectionsTMTpayload,
      };

      let workOrderPitStopPayload = {
        id_car: +this.props.match.params.carId,
        id_shop: +ShopStore.currentShop.id,
        status: 'open',
        type: 'driver_identified',
        priority: workOrder[0].repairPriority,
        created_by: workOrder[0].createdBy,
        assigned_to: workOrder[0].assignedTo,
        workOrderDvir,
      };

      
      const response = await WorkOrderStore.createWorkOrderOnTMTCustomEndpoint({
        workOrderTMTpayload,
        workOrderPitStopPayload,
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  createWorkOrderLines = async (workOrderID) => {
    try {
      let workOrderLines = [];
      this.state.defectsToCreateWorkOrder.forEach((defects) => {
        workOrderLines.push({
          description: defects.comment,
          title: defects.defectType,
        });
      });

      const response = await WorkOrderStore.saveWorkOrderLinesOnCustomEndpoint(
        workOrderID,
        { workOrderLines }
      );

      return response;
    } catch (err) {
      throw err;
    }
  };

  handleCreateTMTWorkOrderClick = async (defectsToCreateWorkOrder = []) => {
    if (defectsToCreateWorkOrder.length === 0) {
      AppStore.addError('No defects to create work order');
      return;
    }

    AppStore.openModals.set('CREATE_TMT_WORK_ORDER_MODAL_ID', true);

    // show modal to confirm
    this.setState({
      defectsToCreateWorkOrder,
    });
  };

  handleCreateTMTWorkOrder = async (workOrder) => {
    try {
      console.log('At DVIR level', workOrder);

      this.setState({ isCreatingTMTWorkOrder: true });
      if (!CurrentUserStore.user.email) {
        throw new Error('An user is required to create a work order');
      }

      const newWorkOrder = await this.createTMTWorkOrderFromDefects(workOrder);

      if (!newWorkOrder) {
        return;
      }

      notification.open({
        message: 'Work Order created successfully.',
        duration: 0,
        description: (
          <div>
            <button
              type="link"
              onClick={() => this.navegateToNewWorkOrder(newWorkOrder.id)}
              style={{
                textDecoration: 'underline',
                color: '#1890ff',
                border: 'none',
                padding: 0,
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
            >
              View Full Work Order: #{newWorkOrder.invoice_number}
            </button>
          </div>
        ),
      });

      this.setState({
        defectsToCreateWorkOrder: [],
      });

      // Refresh the states
      this.init();

      this.setState({ isCreatingTMTWorkOrder: false });
      AppStore.openModals.set('CREATE_TMT_WORK_ORDER_MODAL_ID', false);
    } catch (error) {
      console.error(error);
      AppStore.addError(
        'Error creating work order for the selected defects. Please try again later'
      );
    } finally {
      this.setState({ isCreatingTMTWorkOrder: false });
      AppStore.openModals.set('CREATE_TMT_WORK_ORDER_MODAL_ID', false);
    }
  };

  getSafetyStatusColor = () => {
    if (this.state.dvirData?.payload?.safetyStatus === 'safe') {
      return 'green';
    }
    if (this.state.dvirData?.payload?.safetyStatus === 'unsafe') {
      return 'red';
    }
    return 'ocean';
  };

  getSafetyStatusText = () => {
    if (this.state.dvirData?.payload?.safetyStatus === 'safe') {
      return 'SAFE';
    }
    if (this.state.dvirData?.payload?.safetyStatus === 'unsafe') {
      return 'UNSAFE';
    }
    return 'RESOLVED';
  };

  render() {
    if (this.state.loading) {
      return <div>Loading DVIR data...</div>;
    }
    // const statusColor = this.getStatusColor(this.state.dvirData?.safetyStatus);
    return (
      <Fragment>
        <TitleWrapper>
          <Title level={2} style={{ marginBottom: 0 }}>
            Driver Vehicle Inspection Reports -{' '}
            {// if trailer DVIR, show trailer name
            this.state.dvirData?.payload?.vehicle?.name
              ? `Vehicle # ${this.state.dvirData?.payload?.vehicle?.name}`
              : `Trailer # ${this.state.dvirData?.payload?.trailer?.name}`}
          </Title>
          {/* <Tag className="tag" color={statusColor}>
            {this.formatSafetyStatus(this.state.dvirData?.safetyStatus)}
          </Tag> */}
          <ButtonWrapper>
            <PitstopDropdown
              className="pitstopDropdown"
              overlay={
                <Menu
                  onClick={() => {
                    this.handleDVIRResolveClick();
                  }}
                >
                  <Menu.Item key={'resolved'}>RESOLVED (SAFE)</Menu.Item>
                </Menu>
              }
              placement="bottomCenter"
              disabled={this.state.dvirData?.payload?.safetyStatus !== 'unsafe'}
            >
              <Button
                className={`${this.getSafetyStatusColor()} ${
                  this.state.dvirData?.payload?.safetyStatus !== 'unsafe'
                    ? 'disabled'
                    : ''
                }`}
                disabled={this.state.dvirData?.payload?.safetyStatus === 'safe'}
              >
                {this.getSafetyStatusText()}
                {this.state.dvirData?.payload?.safetyStatus !== 'unsafe' ? (
                  <></>
                ) : (
                  <Icon type="down" />
                )}
              </Button>
            </PitstopDropdown>
          </ButtonWrapper>
        </TitleWrapper>
        {/* Create two rows with 3 columns inside each */}
        <GeneralDataGrid style={{ marginTop: '1rem' }}>
          <div className="grid-item">
            <Text strong>Inspection Type:</Text>
            <Text style={{ marginLeft: '1rem' }}>
              {this.formatType(this.state.dvirData?.payload?.type)}
            </Text>
          </div>
          <div className="grid-item">
            <Text strong>Created By:</Text>
            <Text style={{ marginLeft: '1rem' }}>
              {
                this.state.dvirData?.payload?.authorSignature?.signatoryUser
                  ?.name
              }
            </Text>
          </div>
          <div className="grid-item">
            <Text strong>
              {// if trailer DVIR, show trailer name
              this.state.dvirData?.payload?.vehicle?.name
                ? 'Vehicle'
                : 'Trailer'}{' '}
              ID:
            </Text>
            <Text style={{ marginLeft: '1rem' }}>
              {this.state.dvirData?.payload?.vehicle?.name ||
                this.state.dvirData?.payload?.trailer?.name}
            </Text>
          </div>
          <div className="grid-item">
            <Text strong>Odometer:</Text>
            <Text style={{ marginLeft: '1rem' }}>
              {this.state.dvirData?.payload?.odometerMeters}
            </Text>
          </div>
        </GeneralDataGrid>

        <SecondRowGeneralDataGrid>
          <div className="grid-item">
            <Text strong>Location:</Text>
            <Text style={{ marginLeft: '1rem' }}>
              {this.state.dvirData?.payload?.location || 'N/A'}
            </Text>
          </div>
          <div className="grid-item">
            <Text strong>Mechanic Notes:</Text>
            <Text style={{ marginLeft: '1rem' }}>
              {this.state.dvirData?.payload?.mechanicNotes || 'N/A'}
            </Text>
          </div>
        </SecondRowGeneralDataGrid>

        {this.state.dvirData?.payload?.vehicleDefects?.length > 0 && (
          <>
            <Divider />
            <VehicleDefectsRow gutter={16}>
              <Col span={24}>
                <Title level={3}>Vehicle Defects</Title>
              </Col>
              <Col span={24}>
                <VehicleDefectsTable
                  data={this.state.dvirData?.payload?.vehicleDefects}
                  childRef={(ref) => (this.vehicleDefectsTableRef = ref())}
                  fetchError={this.state.fetchError}
                  loading={this.state.loading}
                  onDefectResolveClick={this.handleDefectResolveClick}
                  onDefectMechanicNotesChange={
                    this.handleDefectMechanicNotesChange
                  }
                  onSaveMechanicNotes={this.handleMechanicNotesChangeSave}
                  onConfirmResolveDefect={this.handleDefectResolveConfirm}
                  onMarkAllAsResolvedClick={this.handleMarkAllAsResolvedClick}
                  onCreateWorkOrderFromDefectsClick={
                    this.handleCreateWorkOrderClick
                  }
                  onCreateTMTWorkOrderFromDefectsClick={
                    this.handleCreateTMTWorkOrderClick
                  }
                />
              </Col>
            </VehicleDefectsRow>
          </>
        )}

        {this.state.dvirData?.payload?.trailerDefects?.length > 0 && (
          <>
            {/* <Divider />
            <VehicleDefectsRow gutter={16}>
              <Col span={24}>
                <Title level={3}>Trailer Defects</Title>
              </Col>
              {this.state.dvirData?.payload?.trailerDefects.map(
                (defect, index) => {
                  return (
                    <Col key={defect.id} span={6}>
                      <Card
                        className="card"
                        style={{ width: '100%' }}
                        cover={
                          <img
                            height={250}
                            className="image"
                            alt="example"
                            src="https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ="
                          />
                        }
                      >
                        <Title style={{ marginBottom: 0 }} level={3}>
                          {defect.defectType}
                        </Title>
                        <Text type="secondary">
                          Added on {moment(defect.createdAtTime).format('lll')}
                        </Text>
                        <Divider />
                        <Text strong>Driver Notes</Text>
                        <FakeTextArea>
                          <Text>{defect.comment}</Text>
                        </FakeTextArea>
                        <div style={{ width: '100%' }}>
                          <CustomTag>
                            <Text
                              className={defect.isResolved ? 'green' : 'red'}
                              style={{ color: 'white' }}
                              strong
                            >
                              {defect.isResolved ? 'RESOLVED' : 'UNRESOLVED'}
                            </Text>
                          </CustomTag>
                        </div>
                      </Card>
                    </Col>
                  );
                }
              )}
            </VehicleDefectsRow> */}
            <Divider />
            <VehicleDefectsRow gutter={16}>
              <Col span={24}>
                <Title level={3}>Trailer Defects</Title>
              </Col>
              <Col span={24}>
                <VehicleDefectsTable
                  createTmtWorkOrdersEnabled={false}
                  data={this.state.dvirData?.payload?.trailerDefects}
                  childRef={(ref) => (this.trailerDefectsTableRef = ref())}
                  fetchError={this.state.fetchError}
                  loading={this.state.loading}
                  onDefectResolveClick={(key, defect) => {
                    this.handleDefectResolveClick(key, defect, 'trailers');
                  }}
                  onDefectMechanicNotesChange={(defect, value) => {
                    this.handleDefectMechanicNotesChange(
                      defect,
                      value,
                      'trailers'
                    );
                  }}
                  onSaveMechanicNotes={(defect, mechanicNotes) => {
                    this.handleMechanicNotesChangeSave(
                      defect,
                      mechanicNotes,
                      'trailers'
                    );
                  }}
                  onConfirmResolveDefect={(defect) => {
                    this.handleDefectResolveConfirm(defect, 'trailers');
                  }}
                  onMarkAllAsResolvedClick={this.handleMarkAllAsResolvedClick}
                  onCreateWorkOrderFromDefectsClick={
                    this.handleCreateWorkOrderClick
                  }
                />
              </Col>
            </VehicleDefectsRow>
          </>
        )}

        <Divider />
        <ConfirmationRow gutter={16}>
          <Col span={24}>
            <Title style={{ marginBottom: 0 }} level={3}>
              Confirmation
            </Title>
            <Text type="secondary">Driver Signature</Text>
          </Col>
          <Col style={{ marginTop: '1rem' }} span={18}>
            <Text>
              "I certify that this vehicle has been inspected in accordance with
              the applicable requirements"
            </Text>
            <br />
            <Text type="secondary">
              {moment(
                this.state.dvirData?.payload?.authorSignature?.signedAtTime
              ).format('lll')}
            </Text>
          </Col>
          <Col span={6}>
            <SignatureRow>
              <div className="driver-name">
                <Text strong>
                  {
                    this.state.dvirData?.payload?.authorSignature?.signatoryUser
                      ?.name
                  }
                </Text>
              </div>
              <div className="driver-signature">
                <Text type="secondary">Driver Signature</Text>
              </div>
            </SignatureRow>
          </Col>
        </ConfirmationRow>

        {this.state.dvirData?.payload?.secondSignature?.signatoryUser?.name && (
          <>
            <Divider />
            <ConfirmationRow gutter={16}>
              <Col span={24}>
                <Title style={{ marginBottom: 0 }} level={3}>
                  &nbsp;
                </Title>
                <Text type="secondary">Mechanic Signature</Text>
              </Col>
              <Col style={{ marginTop: '1rem' }} span={18}>
                <Text>
                  "This unsafe DVIR has been resolved and the vehicle is now
                  safe to drive."
                </Text>
                <br />
                <Text type="secondary">
                  {moment(
                    this.state.dvirData?.payload?.secondSignature?.signedAtTime
                  ).format('lll')}
                </Text>
              </Col>
              <Col span={6}>
                <SignatureRow>
                  <div className="driver-name">
                    <Text strong>
                      {
                        this.state.dvirData?.payload?.secondSignature
                          ?.signatoryUser?.name
                      }
                    </Text>
                  </div>
                  <div className="driver-signature">
                    <Text type="secondary">Mechanic Signature</Text>
                  </div>
                </SignatureRow>
              </Col>
            </ConfirmationRow>
          </>
        )}

        {/* Handle DVIR resolve modal */}
        <DarkHeaderModal
          title="Resolve DVIR"
          visible={this.state.resolveDefectModalVisible}
          onCancel={this.handleResolveDVIRCancel}
          footer={[
            <Button key="back" onClick={this.handleResolveDVIRCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleResolveDVIR}
              loading={this.state.isResolvingDVIR}
            >
              Resolve
            </Button>,
          ]}
        >
          <Text strong>Resolved By:</Text>
          <Input
            value={CurrentUserStore.user.email}
            disabled={true}
            style={{
              width: '100%',
              display: 'block',
              marginBottom: '1rem',
            }}
          />
          <Text style={{ marginTop: '1rem' }} strong>
            Mechanic Notes:
          </Text>
          <TextArea
            rows={4}
            value={this.state.mechanicNotes}
            onChange={(e) => this.setState({ mechanicNotes: e.target.value })}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <Input
              type="checkbox"
              style={{
                marginRight: '1rem',
                flex: 1,
                height: 15,
              }}
              id="resolveAllDefectsCheckbox"
            />
            <label
              htmlFor="resolveAllDefectsCheckbox"
              style={{ flex: 11, cursor: 'pointer' }}
            >
              <Text type="secondary">
                I want to resolve all{' '}
                <b>
                  {
                    get(
                      this,
                      'state.dvirData.payload.vehicleDefects',
                      []
                    ).filter((d) => !d.resolvedBy).length
                  }
                </b>{' '}
                defects in this DVIR as well and apply the mechanic note written
                above to each defect
              </Text>
            </label>
          </div>
        </DarkHeaderModal>

        {/* Confirm resolve defects */}
        <DarkHeaderModal
          title="Resolve Defect(s)"
          visible={this.state.resolveSelectedDefectsModalVisible}
          onCancel={this.handleResolveSelectedDefectsCancel}
          footer={[
            <Button
              key="back"
              onClick={this.handleResolveSelectedDefectsCancel}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleResolveSelectedDefects}
              loading={this.state.isResolvingDefects}
            >
              Resolve
            </Button>,
          ]}
        >
          <Text strong>Resolved By:</Text>
          <Input
            value={CurrentUserStore.user.email}
            disabled={true}
            style={{
              width: '100%',
              display: 'block',
              marginBottom: '1rem',
            }}
          />
          <Text style={{ marginTop: '1rem' }} strong>
            Mechanic Notes{' '}
            <i>
              (If resolving multiple defects at once, notes entered below will
              be added to all defects)
            </i>
            :
          </Text>
          <TextArea
            rows={4}
            onChange={(e) =>
              this.setState({
                mechanicNotesResolvingMultipleDefects: e.target.value,
              })
            }
          />
        </DarkHeaderModal>

        {/* Confirm Create Work Order from defects */}
        <CreateWorkOrderModal
          key={1}
          modalId="CREATE_WORK_ORDER_MODAL_ID"
          issues={this.state.defectsToCreateWorkOrder}
          title={'Create Work Order'}
          isLoading={this.state.isCreatingWorkOrder}
          loading={false}
          onSave={this.handleCreateWorkOrder}
          type="defects"
        />

        {/* Confirm Create TMT Work Order from defects */}
        <CreateTMTWorkOrderModal
          key={2}
          modalId="CREATE_TMT_WORK_ORDER_MODAL_ID"
          issues={this.state.defectsToCreateWorkOrder}
          title={'Create Work Order'}
          isLoading={this.state.isCreatingTMTWorkOrder}
          loading={false}
          onSave={this.handleCreateTMTWorkOrder}
        />
      </Fragment>
    );
  }
}

export default observer(DVIRDetailsPage);
